import request from "@/utils/request";
import qs from "qs";

export function queryUserList(data) {
  return request({
    url: "user/queryUserList",
    method: "get",
    params: data,
  });
}

export function queryUserById(userId) {
  return request({
    url: "user/queryUserById",
    method: "get",
    params: userId,
  });
}
export function updateUserById(user) {
  return request({
    url: "user/updateUserById",
    method: "put",
    data: user,
  });
}
export function userExportData(data) {
  return request({
    url: "user/updateUserById",
    method: "put",
    data: data,
  });
}

export function deleteUserById(userId) {
  return request({
    url: "user/deleteUserById",
    method: "delete",
    data: qs.stringify(userId, { arrayFormat: "comma" }),
  });
}

export function getCertificateByUid(uid) {
  return request({
    url: "userCertificate/getCertificateByUid?uid=" + uid,
    method: "get",
  });
}

export function deleteCertificateByUid(data) {
  return request({
    url: "userCertificate/deleteCertificateByUid",
    method: "post",
    data: data,
  });
}
